<template>
  <div class="mt-5">
    <div class="page-content-inner pt-lg-0 pr-lg-0 mt-4">
      <div class="uk-grid-medium" uk-grid>
        <div class="section-header pb-0 mt-2 uk-width-4-4@m">
          <div class="section-header-left">
            <h3 class="uk-text-truncate"><i class="uil-book-open"></i> {{ $t('sideNav.provider_list') }}</h3>
          </div>
        </div>
        <div class="uk-width-4">
          <div class="section-header pb-4">
            <div class="section-header-left">
              <form action="#">
                <input v-model="searchQuery" :placeholder="$t('filters.search')" type="text">
              </form>

            </div>
            <!-- <div class="uk-width-expand@s"> -->
            <button type="button" class="btn  uil-search-alt ml-1" @click="searchProvider(searchQuery)">
              {{ $t('general.search') }}
            </button>
            <!-- </div> -->
          </div>
          <default-loading v-if="assignment_loading || page_loading"></default-loading>
          <template v-else>
            <div class="uk-child-width-1-4@m uk-child-width-1-4@s course-card-grid uk-grid-match mt-2" uk-grid>
              <template v-for="(provider_item, index) in results">
                <div v-if="'provider_item_' + index" :key="'provider_item_' + index">
                  <div class="course-path-card mb-2 uk-link" @click="openProviderDetails(provider_item)">
                    <img :src="provider_item.image" alt="" style="width:100%">
                    <div class="course-path-card-contents">
                      <div class="course-card-info d-flex justify-content-between">
                        <div>
                          <span class="catagroy">{{ provider_item.provider }}</span>
                        </div>
                      </div>
                      <h3>{{ provider_item.title }}</h3>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="uk-flex uk-flex-center p-4">
              <button v-if="items.last_page > pageNumber || trainingItems.last_page > pageNumber"
                class="btn btn-info button_more" @click.prevent="itemsMoreButton">{{ $t('general.more') }}
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EducationCard from "@/view/components/index/EducationCard";
import Pagination from "@/view/components/dataTable/Pagination";
import EducationCardList from "@/view/components/index/EducationCardList";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/az';
import 'moment/locale/tr';
import 'moment/locale/en-gb';
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import { GET_COUNTS, GET_ITEMS, LOADING } from "@/core/services/store/REST.module";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import _ from "lodash";


import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
import axios from "axios";
let latestForAssigmentRequest = null;
let latestForTrainingRequest = null;

export default {
  name: "MyEducationOrAssignment",
  components: {
    Multiselect, MultipleSelectInput,
    EducationCard,
    EducationCardList,
    Pagination,
    DefaultLoading
  },
  data() {
    return {
      page_loading: false,
      filterTypes: [
        {
          id: 'name',
          name: this.$t('filter.Filter_by_name')
        },
        {
          id: 'created_at',
          name: this.$t('filter.Filter_by_date')
        },
        /* {
          id: 'category_id',
          name: this.$t('filter.Filter_by_category')
        }, */
      ],
      restUrl: 'api/provider-search',
      restTrainingUrl: 'api/training',
      searchQuery: "",
      isTyping: false,
      allItems: [],
      searchResult: [],
      activeTab: 1,
      items: [],
      results: [],
      trainingItems: [],
      hashtags: [],
      searchTag: '',
      recentTagsUrl: 'api/recent_tag',
      defaultFilterOrderBy: { "id": "name", "name": "İsme Göre" },
      pageNumber: 1,
      filters: {
        just_assigned: true,
        selected_hashtag: null,
        selected_hashtag_id: null,
        completed: false,
        on_progress: false,
        repeat_it: false,
        with_expired: false,
        by_user: true,
        order_by: { "id": "name", "name": "İsme Göre" },
        sort: 'desc',
        perPage: 9,
        search: null,
      },
      trainingFilters: {
        q: '',
        by_user: true,
        training_type_id: 0,
        from_user: true,
        just_assigned: false,
        completed: false,
        on_progress: false,
        repeat_it: false,
        perPage: 9
      },
      assigmentCounts: {},
    };
  },
  computed: {
    ...mapGetters({
      assignment_loading: LOADING
    }),
    filteredTags() {
      const tags = this.hashtags
      const search = this.searchTag
      if (search === '') return tags
      return tags.filter((tag) => {
        return tag.name.toLowerCase().includes(search.toLowerCase());
      });
    },
    sortedAllItems() {
      var orderBy = this.filters.order_by.id
      this.allItems = [];
      if (this.trainingItems.data || this.items.data) {
        if (this.items.data) {
          this.items.data.forEach(element => {
            if (!this.allItems.find(item => item.id == element.id)) {
              this.allItems.push(element)
            }
          });
        }
        if (this.trainingItems.data) {
          this.trainingItems.data.forEach(element => {
            if (!this.allItems.find(trainingItem => trainingItem.id == element.id)) {
              this.allItems.push(element)
            }
          });
        }
      }
      if (orderBy == "name") {
        return this.allItems.sort(function (a, b) {
          return a[orderBy].localeCompare(b[orderBy]);
        });
      }
      return this.allItems.sort((a, b) => a[orderBy] - b[orderBy])
    }
  },
  methods: {
    itemsMoreButton() {
      let self = this;
      self.allItems = []
      self.pageNumber++;
      if (self.pageNumber > self.items.last_page && self.pageNumber > self.trainingItems.last_page) {
        return;
      } else {
        let filters = _.clone(self.filters)
        this.$set(filters, 'page', self.pageNumber)
        this.$set(filters, 'order_by', self.filters.order_by.id)
        this.$set(filters, 'perPage', self.filters.perPage)
        this.$set(filters, 'by_user', self.filters.by_user)
        this.$store.dispatch(GET_ITEMS, {
          url: self.restUrl,
          acceptPromise: false,
          showLoading: true,
          filters: filters
        }).then(result => {
          if (result.status) {
            self.addFormattedItem(result.data.data)
          }
        })
      }
      this.$set(this.trainingFilters, 'page', self.pageNumber)
      this.$set(this.trainingFilters, 'order_by', this.filters.order_by.id)
      this.$set(this.trainingFilters, 'perPage', this.trainingFilters.perPage)
      this.$set(this.trainingFilters, 'by_user', this.trainingFilters.by_user)
      this.$set(this.trainingFilters, 'from_user', this.trainingFilters.from_user)
      this.$set(this.trainingFilters, 'just_assigned', this.trainingFilters.just_assigned)
      this.$set(this.trainingFilters, 'repeat_it', this.trainingFilters.repeat_it)
      this.$store.dispatch(GET_ITEMS, {
        url: this.restTrainingUrl,
        acceptPromise: false,
        showLoading: true,
        filters: this.trainingFilters
      }).then(result => {
        if (result.status) {
          this.addProviderFormattedItem(result.data.data)
        }
      })
    },
    searchProvider(searchQuery) {
      let self = this
      if (this.searchQuery) {
        this.items = [];
        self.pageNumber = 1;
      }
      let filters = _.clone(this.filters)
      // this.$set(filters, 'page', this.pageNumber)
      // this.$set(filters, 'perPage', this.filters.perPage)
      // this.$set(filters, 'search', searchQuery)
      // this.$set(filters, 'order_by', this.filters.order_by.id)
      // this.$set(filters, 'by_user', this.filters.by_user)
      // this.$set(filters, 'hashtag_id', this.filters.selected_hashtag_id)
      let trainingFilters = _.clone(self.trainingFilters)
      this.$set(trainingFilters, 'page', self.pageNumber)
      this.$set(trainingFilters, 'order_by', this.filters.order_by.id)
      this.$set(trainingFilters, 'perPage', this.trainingFilters.perPage)
      this.$set(trainingFilters, 'by_user', this.trainingFilters.by_user)
      this.$set(trainingFilters, 'q', searchQuery)
      this.$set(trainingFilters, 'from_user', this.trainingFilters.from_user)
      this.$set(trainingFilters, 'repeat_id', this.trainingFilters.repeat_id)
      this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        filters: trainingFilters,
        showLoading: true,
      }).then(result => {
        if (result.status) {
          // self.items = result.data;
          this.results = result.data.data;
          this.items = result.data.data;
          this.setFormattedItems(result.data)
        }
      })
    },
    changeToList() {
      this.activeTab = 1;
    },
    changeToCard() {
      this.activeTab = 2;
    },
    timeFromDate(value) {
      const today = new Date()
      var inputDate = new Date(value);
      var todaysDate = new Date();
      if (inputDate < todaysDate) {
        moment.locale(this.secureLocalStorage("language"))
        return moment(inputDate).format('DD MMMM YYYY')
      } else {
        return moment(value).fromNow() + ' ' + this.$t('başlıyor');
      }
    },
    getRecentTags() {
      let self = this;
      self.$store.dispatch(GET_ITEMS, {
        url: self.recentTagsUrl,
        acceptPromise: false,
      }).then(result => {
        if (result.status) {
          self.hashtags = result.data
        }
      })
    },
    clickedRadioButton($event) {
      if ($event && $event.target.checked) {
        if (+$event.target.value === this.filters.selected_hashtag_id) {
          $event.target.checked = false;
          this.filters.selected_hashtag = null;
          this.filters.selected_hashtag_id = null;
          $('.uk-radio').blur();
          this.applyFilter();
        } else {
          this.filters.selected_hashtag_id = Number($event.target.value);
        }
      }
    },
    async applyFilter() {
      this.page_loading = true
      let self = this;
      let filters = _.clone(self.filters)
      let trainingFilters = _.clone(self.trainingFilters)
      self.pageNumber = 1;
      this.$set(filters, 'page', this.pageNumber)
      this.$set(filters, 'perPage', this.filters.perPage)
      this.$set(filters, 'search', this.filters.search)
      this.$set(filters, 'order_by', this.filters.order_by.id)
      this.$set(filters, 'order', this.filters.sort)
      this.$set(filters, 'by_user', this.filters.by_user)
      this.$set(filters, 'hashtag_id', this.filters.selected_hashtag_id)
      this.$set(filters, 'completed', this.filters.completed)
      if (this.filters.completed === true) {
        this.$set(filters, 'with_expired', false)
      } else {
        this.$set(filters, 'with_expired', this.filters.with_expired)
      }
      //this.$set(this.filters, 'with_expired', this.filters.with_expired)
      this.$set(filters, 'repeat_it', this.filters.repeat_it)
      this.$set(filters, 'on_progress', this.filters.on_progress)
      this.$set(trainingFilters, 'page', self.pageNumber)
      this.$set(trainingFilters, 'order_by', this.filters.order_by.id)
      this.$set(trainingFilters, 'perPage', this.trainingFilters.perPage)
      this.$set(trainingFilters, 'by_user', this.trainingFilters.by_user)
      this.$set(trainingFilters, 'from_user', this.trainingFilters.from_user)
      this.$set(trainingFilters, 'repeat_id', this.trainingFilters.repeat_id)

      latestForAssigmentRequest = JSON.stringify(filters);
      latestForTrainingRequest = JSON.stringify(trainingFilters);

      await this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        filters: filters,
        showLoading: true
      }).then(result => {
        if (result.status) {
          this.results = result.data.data;
          this.items = result.data.data;
          this.page_loading = false;
        }
      })
    },
    setFilterItem(key, value) {
      this.allItems = [];
      this.pageNumber = 1;
      this.resetPartialFilters()
      this.filters[key] = value;
      this.trainingFilters[key] = value;
      this.applyFilter()
    },
    resetPartialFilters() {
      this.filters.just_assigned = false
      this.filters.completed = false
      this.filters.on_progress = false
      this.filters.repeat_it = false
      this.filters.with_expired = false
      this.filters.order_by = this.defaultFilterOrderBy
      this.trainingFilters.completed = false
      this.trainingFilters.on_progress = false
      this.trainingFilters.just_assigned = false
      this.trainingFilters.repeat_it = false
    },
    setFormattedItems(items) {
      let results = [];
      if (typeof items.data !== "undefined") {
        items.data.forEach((currentItem) => {
          results.push(this.providerFormatItem(currentItem));
        });
      }
      items.data = results
      if (this.filters.repeat_it) {
        items.data.sort(function (a, b) {
          return a.order_number - b.order_number;
        });
      }
      this.items = items
      return true;
    },
    addFormattedItem(items) {
      let self = this
      if (typeof items !== "undefined") {
        items.forEach((currentItem) => {
          self.items.data.push(this.providerFormatItem(currentItem));
        });
      }
    },
    providerFormatItem(item) {
      return {
        id: item.id,
        name: item.name,
        cover_image_link: item.cover_image_link,
        description: item.description ? item.description : "-",
        has_present: item.has_present,
        duration: item.duration,
        time: item.start_date,
        end_date: item.end_date,
        repeatable: item.repeatable,
        order_number: item.order_number,
        bottom_title: this.getBottomTitle(item.completion_percentage),
        completion_percentage: item.completion_percentage,
        is_completed: item.is_completed,
        sub_title: item.is_compulsory == true ? this.$t('assignment.compulsory') : this.$t('assignment.not_compulsory'),
        link_type: 1,
        is_watchable_again: item.is_watchable_again
      }
    },
    setProviderFormattedItems(items) {
      let results = [];
      let data = Object.values(items.data);
      data.forEach((currentItem) => {
        results.push(this.trainingFormatItem(currentItem));
      });
      items.data = results
      this.trainingItems = items
      return true;
    },
    addProviderFormattedItem(items) {

      let self = this

      if (self.trainingItems.data) {
        let newData = [];
        self.trainingItems.data = newData.concat(self.trainingItems.data)
      } else {
        self.trainingItems.data = self.trainingItems.data;
      }
      items.forEach((currentItem) => {
        self.trainingItems.data.push(this.trainingFormatItem(currentItem));

      });
    },
    trainingFormatItem(item) {
      if (!this.CatalogTrainingVisible) {
        return false;
      }
      return {
        id: item.id,
        name: item.name,
        cover_image_link: item.cover_image_link,
        description: item.description ? item.description : "-",
        duration: item.duration,
        time: item.created_at,
        bottom_title: this.getBottomTitle(item.completion_percentage),
        completion_percentage: item.completion_percentage,
        is_completed: item.is_completed,
        sub_title: this.$t('assignment.not_compulsory'),
        link_type: 2
      }
    },
    getBottomTitle(percentage) {
      if (percentage === 0)
        return this.$t('general.start')
      else if (percentage >= 100) {
        if (this.is_watchable_again && !this.repeatable)
          return this.$t('İzle')
        else if (this.repeatable)
          return this.$t('Yeniden Al')
        else {
          return this.$t('general.detail')
        }
      }
      else {
        return this.$t('general.continue')

      }
      // switch (percentage) {
      //   case 0:
      //     return this.$t('general.start')
      //   case (percentage >= 100):
      //     if (this.is_watchable_again && !this.repeatable)
      //       return this.$t('İzle')
      //     else if (this.repeatable)
      //       return this.$t('Yeniden Al')
      //     else {
      //       return this.$t('Detay')
      //     }
      //   default:
      //     return this.$t('general.continue')
      // }
    },
    getAssignmentCounts() {
      let self = this;
      this.$store.dispatch(GET_COUNTS, {
        url: this.restUrl + '/counts',
        acceptPromise: false,
        showLoading: true
      }).then(result => {
        self.assigmentCounts = result.data;
      })
    },
    openProviderDetails(result) {
      axios.get(process.env.VUE_APP_BACKEND_APP_URL + "/api/provider-content-show/" + result.provider + "/" + result.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.secureLocalStorage("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.$router.push({ name: "user.assignment-detail", params: { id: response.data.assignment_id } });
          } else {
            // TODO kontrol edilmeli 404 gelebilyior
          }
        });
    },
  },
  watch: {
    // searchQuery: _.debounce(function () {
    //   this.isTyping = false;
    // }, 1000),
    // isTyping: function (value) {
    //   // if (!value) {
    //   //   this.searchUser(this.searchQuery);
    //   // }
    // },
  },
  mounted() {
    this.getAssignmentCounts()
    // this.getRecentTags()
    this.applyFilter()
  }
}
</script>

<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.button_more {
  border-radius: 50% !important;
  height: 70px;
  width: 70px;
  background-color: var(--backgroundColor) !important;

}

.responsive-tab li a.active {
  color: #3e416d;
  opacity: 0.8;
}

.form-group {
  margin-bottom: 0 !important;
}
</style>

<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
